import styled from 'styled-components'
import { v, fontSize } from 'styles/variables.js'

export const InputOutBox = styled.div`
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;

  /* input:focus::-webkit-input-placeholder {
    font-size: 0.75em;
    position: relative;
    top: -15px;
    transition: 0.2s ease-out;
  }

  input::-webkit-input-placeholder {
    transition: 0.2s ease-in;
  }

  input[type='text']:focus,
  input[type='password']:focus {
    height: 50px;
    padding-bottom: 0px;
    transition: 0.2s ease-in;
  }

  input[type='text'],
  input[type='password'] {
    height: 50px;
    transition: 0.2s ease-in;
  } */
`

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: row;
`

export const InputTitle = styled.div`
  font-weight: 500;
  font-size: ${fontSize.mdText};
  line-height: 26px;
  display: flex;
  /* width: ${({ titleWidth }) => (titleWidth ? titleWidth : 'auto')}; */
  width: ${({ layout, titleWidth }) => (layout === 'horizontal' ? titleWidth : 'auto')};
  white-space: nowrap;
  align-items: center;
  margin: ${({ layout }) => (layout === 'horizontal' ? '5px 0px' : '0 0 10px 0')};
  color: ${({ theme }) => theme.text};
`

export const InputDescription = styled.div`
  font-weight: 300;
  font-size: ${fontSize.smText};
  line-height: 26px;
  display: flex;
  /* width: 100%; */
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  margin: ${({ layout }) => (layout === 'horizontal' ? '5px 0px' : '0 0 10px 0')};
  color: ${({ theme }) => theme.grey};
`

export const InputGroup = styled.div`
  display: ${({ layout }) => (layout === 'horizontal' ? 'flex' : 'block')};
  flex-direction: row;
`

export const InputBox = styled.input`
  align-self: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid ${({ error }) => (error === 'error' ? 'rgb(250,72,84)' : '#e2e8f0')};
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  border-radius: 15px;
  height: 40px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding-left: 20px;
  font-size: ${fontSize.mdText};
  line-height: 20px;

  :disabled {
    opacity: 0.3;
  }
  ::placeholder {
    color: ${({ error }) => (error === 'error' ? 'rgb(250,72,84)' : null)};
  }
`

export const Icon = styled.div`
  /* position: absolute;
  margin-top: 5px;
  left: 85%; */
  color: ${({ theme }) => theme.danger};
  font-size: 20px;
`
export const NullIcon = styled.div`
  width: 9.95px;
`
