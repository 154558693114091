import React from 'react'
import * as S from './styles.js'
import PropTypes from 'prop-types'
import Tag from '../Tag/index.js'
import Tooltip from '../Tooltip/index.js'
const Input = ({
  title,
  name,
  width,
  height,
  placeholder,
  layout,
  alert,
  titleWidth,
  value,
  type,
  error,
  rules,
  required,
  onChange,
  disabled,
  max,
  min,
  defaultValue,
  maxLength,
  minLength,
  onFocus,
  onBlur,
  ref,
  description,
  tooltip,
  pattern,
  autoComplete,
}) => {
  return (
    <Tooltip toolTipText={tooltip} disabled={!tooltip} name={name}>
      <S.InputOutBox>
        <S.InputGroup layout={layout}>
          <S.TitleGroup>
            {title && (
              <S.InputTitle layout={layout} titleWidth={titleWidth}>
                {required ? <S.Icon>*</S.Icon> : <S.NullIcon />}
                {title}
              </S.InputTitle>
            )}
            <S.InputDescription>{description && description}</S.InputDescription>
          </S.TitleGroup>

          <S.InputBox
            ref={ref}
            defaultValue={defaultValue}
            placeholder={placeholder}
            width={width}
            height={height}
            onChange={onChange}
            value={value}
            type={type}
            name={name}
            error={error}
            {...rules}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            max={max}
            min={min}
            onFocus={onFocus}
            onBlur={onBlur}
            pattern={pattern}
            autoComplete={autoComplete}
          />

          {/* {required && <S.Icon>*</S.Icon>} */}
        </S.InputGroup>
      </S.InputOutBox>
    </Tooltip>
  )
}

Input.propTypes = {
  title: PropTypes.any,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  titleWidth: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['tel', 'text', 'time', 'url', 'password', 'number', 'email']),
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  alert: PropTypes.string,
  rules: PropTypes.object,
  required: PropTypes.bool,
  error: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  defaultValue: PropTypes.string,
  tooltip: PropTypes.string,
  autoComplete: PropTypes.string,
}

Input.defaultProps = {
  width: '100%',
  type: 'text',
  name: 'input-box',
  required: null,
  defaultValue: '',
  tooltip: '',
}

export default Input
