import { newFirmSettingInfo } from 'apis/firmInfo'
import Button from 'components/Elements/Button'
import Input from 'components/Elements/Input'
import Modal from 'components/Elements/Modal'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

const NewFirmSetting = ({ showModal, setShowModal }) => {
  const { firmInfo, userInfo } = useSelector((state) => state.user)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      userName: '',
      userPhone: '',
    },
  })
  useEffect(() => {
    if (showModal) {
      if (showModal.name !== '新商家') {
        setValue('name', showModal.name)
      }
      setValue('userName', showModal.userName)
      setValue('userPhone', showModal.userPhone)
    }
  }, [showModal])

  function onSubmit(data) {
    newFirmSettingInfo({ ...data, firmId: firmInfo.firmId, userId: userInfo.userId })
      .then((res) => {
        toast.success('設定成功')
        setShowModal(null)
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
      .catch((err) => {
        toast.error(err.error.message)
      })
  }

  return (
    <Modal
      footer={null}
      active={showModal ? true : false}
      hideModal={() => setShowModal(null)}
      width="450px"
      maxHeight="800px"
      title="新商家資料設定"
      closeIcon={false}
      header={true}
      isClosable={false}
    >
      <fieldset>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex" style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Input
              title="商家名稱："
              name="name"
              type="text"
              placeholder="請輸入商家名稱...."
              rules={{
                ...register('name'),
              }}
              width="300px"
            />

            <div style={{ marginTop: '10px' }} />
            <Input
              title="姓名："
              name="userName"
              type="text"
              placeholder="請輸入姓名...."
              rules={{
                ...register('userName'),
              }}
              width="300px"
            />
            <div style={{ marginTop: '10px' }} />
            <Input
              title="手機："
              name="userPhone"
              type="text"
              placeholder="請輸入手機號碼...."
              rules={{
                ...register('userPhone'),
              }}
              width="300px"
            />
            <div style={{ marginTop: '10px' }} />

            <Button primary type="submit" width="300px">
              送出
            </Button>
            <div style={{ marginTop: '10px' }} />
          </div>
          <div
            style={{
              fontSize: '12px',
              color: '#888',
              marginBottom: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            因詐騙猖獗與符合NCC規範，於2024/12/23後，幫賣平台進行寄送報名通知信/直播通知信...等相關內容，郵件寄件人名稱將會以"商家名稱"進行寄送（原為使用商家Email）
            <br />
            因此尚在使用預設商家名稱的廠商，請立刻填寫以上表單，更新相關資訊，以免寄送郵件被阻擋或受罰。
          </div>
        </form>
      </fieldset>
    </Modal>
  )
}

export default NewFirmSetting
