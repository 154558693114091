import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

function Tooltip({ children, toolTipText, disabled, name }) {
  return (
    <>
      <a data-tip data-for={name}>
        {children}
      </a>
      {!disabled && (
        <ReactTooltip id={name} type="info" place="bottom" effect="solid">
          <span>{toolTipText}</span>
        </ReactTooltip>
      )}
    </>
  )
}

export default Tooltip

Tooltip.propTypes = {
  children: PropTypes.node,
  toolTipText: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
}
