import React, { lazy, Suspense, useEffect, useState } from 'react'
import { differenceInMonths } from 'date-fns'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles/globalStyles'
import { darkTheme, lightTheme } from './styles/theme'
import Layout from 'components/Elements/Layout'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import Confirm from 'components/Elements/Confirm'
import { Helmet } from 'react-helmet'
import * as API from 'apis/user'
import Loading from 'components/Elements/Loading'
import packageJson from '../package.json'
import zhTW from 'date-fns/locale/zh-TW'
import NewFirmSetting from 'components/NewFirmSetting'

export const ThemeContext = React.createContext(null)

const routes = [
  {
    path: '/dashboard',
    Component: lazy(() => import('./pages/Dashboard/index')),
    exact: true,
    auth: true,
    type: 'dashboard',
  },

  {
    path: '/manage-live',
    Component: lazy(() => import('./pages/Manage-live')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/Manage-blacklist',
    Component: lazy(() => import('./pages/Manage-blacklist')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-form',
    Component: lazy(() => import('./pages/Manage-form')),
    exact: true,
    auth: true,
    type: 'form',
  },
  {
    path: '/manage-form/:pageStatus/:pageType',
    Component: lazy(() => import('./pages/Manage-form/Manage')),
    exact: true,
    auth: true,
    type: 'form',
  },
  {
    path: '/manage-form/:pageStatus/:pageType/:pageId',
    Component: lazy(() => import('./pages/Manage-form/Manage')),
    exact: true,
    auth: true,
    type: 'form',
  },

  // {
  //   path: '/manage-form/create',
  //   Component: lazy(() => import('./pages/Manage-form/Create')),
  //   exact: true,
  //   auth: true,
  // },
  // {
  //   path: '/manage-form/edit/:signupId',
  //   Component: lazy(() => import('./pages/Manage-form/Edit')),
  //   exact: true,
  //   auth: true,
  // },
  {
    path: '/manage-live-stream',
    Component: lazy(() => import('./pages/Manage-live-stream')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-live-stream/streaming-edit/:liveId',
    Component: lazy(() => import('./pages/Manage-live-stream/Streaming-edit')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-live-stream/:pageType/:platformType',
    Component: lazy(() => import('./pages/Manage-live-stream/Manage')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-live-stream/:pageType/:platformType/:liveId',
    Component: lazy(() => import('./pages/Manage-live-stream/Manage')),
    exact: true,
    auth: true,
    type: 'stream',
  },

  {
    path: '/manage-msgroup',
    Component: lazy(() => import('./pages/Manage-msgroup')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-msgroup/create',
    Component: lazy(() => import('./pages/Manage-msgroup/Manage')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-msgroup/edit/:commentModId',
    Component: lazy(() => import('./pages/Manage-msgroup/Manage')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/Manage-product',
    Component: lazy(() => import('./pages/Manage-product')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-sms-email',
    Component: lazy(() => import('./pages/Manage-sms-email')),
    exact: true,
    auth: true,
    type: 'form',
  },
  {
    path: '/manage-sms-email/create',
    Component: lazy(() => import('./pages/Manage-sms-email/Create')),
    exact: true,
    auth: true,
    type: 'form',
  },
  {
    path: '/manage-virtual-user',
    Component: lazy(() => import('./pages/Manage-virtual-user')),
    exact: true,
    auth: true,
    type: 'stream',
  },
  {
    path: '/manage-orders',
    Component: lazy(() => import('./pages/Manage-orders')),
    exact: true,
    auth: true,
  },
  {
    path: '/brand-setting',
    Component: lazy(() => import('./pages/Brand-setting')),
    exact: true,
    auth: true,
    type: 'setting',
  },
  {
    path: '/association-setting',
    Component: lazy(() => import('./pages/Association-setting')),
    exact: true,
    auth: true,
    type: 'setting',
  },
  {
    path: '/partner/:partnerId',
    Component: lazy(() => import('./pages/Association-setting/partner')),
    exact: true,
  },
  {
    path: '/manage-mail-template',
    Component: lazy(() => import('./pages/Manage-mail-template')),
    exact: true,
    auth: true,
    type: 'form',
  },
  {
    path: '/manage-mail-template/create',
    Component: lazy(() => import('./pages/Manage-mail-template/Manage')),
    exact: true,
    auth: true,
    type: 'form',
  },
  {
    path: '/manage-mail-template/edit/:letterTemplateId',
    Component: lazy(() => import('./pages/Manage-mail-template/Manage')),
    exact: true,
    auth: true,
    type: 'form',
  },
  /**
   *
   * 使用者畫面
   *
   */
  {
    path: '/auth/login',
    Component: lazy(() => import('./pages/Auth')),
    exact: true,
  },

  // {
  //   path: '/auth/register',
  //   Component: lazy(() => import('./pages/Auth/login/register')),
  //   exact: true,
  // },

  {
    path: '/auth/forget',
    Component: lazy(() => import('./pages/Auth/login/forget')),
    exact: true,
  },

  {
    path: '/auth/reset/:token',
    Component: lazy(() => import('./pages/Auth/login/reset')),
    exact: true,
  },
  {
    path: '/stream/:shortUrl',
    Component: lazy(() => import('./pages/Front-stream-shortUrl')),
    exact: true,
  },
  // {
  //   path: '/stream/:paramStreamId',
  //   Component: lazy(() => import('./pages/404/client-404')),
  //   exact: true,
  // },
  {
    path: '/stream/:paramStreamId/:userId/replay',
    Component: lazy(() => import('./pages/Front-stream-replay')),
    exact: true,
  },
  {
    path: '/stream/:paramStreamId/expired',
    Component: lazy(() => import('./pages/Stream-end')),
    exact: true,
  },
  {
    path: '/stream/:paramStreamId/anonymous',
    Component: lazy(() => import('./pages/404/client-404')),
    exact: true,
  },
  {
    path: '/stream/:paramStreamId/:userId',
    Component: lazy(() => import('./pages/Front-stream')),
    exact: true,
  },
  {
    path: '/:pageType/:customUrl',
    Component: lazy(() => import('./pages/Front-form-url')),
    exact: true,
  },
  // {
  //   path: '/front-form/:firmId/:signupId',
  //   Component: lazy(() => import('./pages/Front-form')),
  //   exact: true,
  // },
  // {
  //   path: '/front-form/:firmId/:signupId/:associationId',
  //   Component: lazy(() => import('./pages/Front-form')),
  //   exact: true,
  // },

  /**
   *
   * Beta 測試用
   *
   */
  {
    path: '/404',
    Component: lazy(() => import('./pages/404')),
    exact: true,
  },

  {
    path: '/client-404',
    Component: lazy(() => import('./pages/404/client-404')),
    exact: true,
  },
  // {
  //   path: '/stream',
  //   Component: lazy(() => import('./pages/Stream')),
  //   exact: true,
  // },
  // {
  //   path: '/stream/:page',
  //   Component: lazy(() => import('./pages/Stream')),
  //   exact: true,
  // },
  {
    path: '/Proto',
    Component: lazy(() => import('./pages/Proto')),
    exact: true,
  },
  {
    path: '/playground',
    Component: lazy(() => import('./pages/Playground')),
    exact: true,
  },
  {
    path: '/chat',
    Component: lazy(() => import('./pages/Chat')),
    exact: true,
  },
  {
    path: '/playground2',
    Component: lazy(() => import('./pages/Playground2')),
    exact: true,
  },
  {
    path: '/playground/1',
    Component: lazy(() => import('./pages/Playground2/Canvas1')),
    exact: true,
  },
  {
    path: '/playground/2',
    Component: lazy(() => import('./pages/Playground2/Canvas2')),
    exact: true,
  },
  {
    path: '/playground/3',
    Component: lazy(() => import('./pages/Playground2/Canvas3')),
    exact: true,
  },
  {
    path: '/playground/4',
    Component: lazy(() => import('./pages/Playground2/Canvas4')),
    exact: true,
  },
  {
    path: '/playground/5',
    Component: lazy(() => import('./pages/Playground2/Canvas5')),
    exact: true,
  },
]

const Router = ({ history }) => {
  const { show, content, title, footerPosition } = useSelector((state) => state.confirm)
  const dispatch = useDispatch()
  const routerAnimation = 'slide-fadein-up'
  const [theme, setTheme] = useState('light')
  const [redirect, setRedirect] = useState(false)
  const themeStyle = theme === 'light' ? lightTheme : darkTheme
  const token = useSelector((state) => state.user.userInfo.token)
  const [showModal, setShowModal] = useState(null)

  const { bombmyLive, bombmySignup, bombmySetting } = useSelector((state) => state.user.bombmyAuthority)

  const expireDate = useSelector((state) => state.user.firmInfo.liveHoursExpireDate)
  const targetDate = new Date(expireDate)
  const currentDate = new Date()
  const monthsDiff = differenceInMonths(targetDate, currentDate)

  if (monthsDiff < 3) {
    toast.error('提醒您儲值時數不足三個月，請注意！')
  }

  const list = [
    '/sign-up',
    '/stream/',
    '/proto',
    '/front-form',
    '/register',
    '/forget',
    '/reset',
    '/login',
    '/client-404',
    '/partner/',
    '/f/',
    '/s/',
  ]

  useEffect(() => {
    console.log(`version: ${packageJson.version}`)

    //redux沒token但localStorage有token
    const storage = localStorage.getItem('ramate_auth')
    const storageParse = JSON.parse(storage)

    if (token === '' && !storageParse?.token) {
      localStorage.clear()
      setRedirect(true)
    } else {
      API.firmAutoLogin(storageParse)
        .then((res) => {
          const newCsrf = res.headers.get('Grpc-Metadata-Csrf-Token')

          if (res.data.firmInfo.name === '新商家' || res.data.firmInfo.name === '' || res.data.userInfo.userPhone === '') {
            const match = list.reduce((state, current) => {
              if (window.location.pathname.includes(current)) {
                return true
              }
              return state
            }, false)
            if (!match) {
              setShowModal({ name: res.data.firmInfo.name, userName: res.data.userInfo.userName, userPhone: res.data.userInfo.userPhone })
            }
          }
          dispatch({
            type: 'FIRM_LOGIN',
            payload: { ...res.data, deviceId: storageParse.deviceId.toString(), csrf: newCsrf },
          })
        })
        .catch((err) => {
          dispatch({ type: 'FIRM_LOGOUT', payload: null })
          toast.error(err.error.message)
          localStorage.clear()
          setRedirect(true)
        })
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ setTheme, theme }}>
      <Helmet>
        <title>幫賣Bombmy</title>
        <meta name="description" content="BombMy幫賣，讓你的產品爆賣！" />
        <meta property="og:title" content="幫賣Bombmy" />
        <meta property="og:description" content="BombMy幫賣，讓你的產品爆賣！" />
        <meta property="og:image" content="/resources/images/logos/logo512_big.webp" />
      </Helmet>
      <ThemeProvider theme={themeStyle}>
        <GlobalStyle />
        <Toaster position="top-center" />
        <Confirm
          onClick={() => dispatch({ type: 'CONFIRM_ONCLICK' })}
          show={show}
          hide={() => dispatch({ type: 'CONFIRM_HIDE' })}
          title={title}
          content={content}
          footerPosition={footerPosition}
        />
        <NewFirmSetting showModal={showModal} setShowModal={setShowModal} />
        <ConnectedRouter history={history}>
          <Route
            render={(state) => {
              const { location } = state

              return (
                <Layout path={history}>
                  <SwitchTransition>
                    <CSSTransition key={location.pathname} appear classNames={routerAnimation} timeout={300}>
                      <Switch location={location}>
                        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                        {routes.map(({ path, Component, exact, auth, type }) => (
                          <Route
                            path={path}
                            key={path}
                            exact={exact}
                            render={() => {
                              return !auth || (auth && token) ? (
                                <>
                                  {!auth ||
                                  (type === 'form' && bombmySignup !== 0) ||
                                  (type === 'stream' && bombmyLive !== 0) ||
                                  (type === 'setting' && bombmySetting !== 0) ||
                                  type === 'dashboard' ? (
                                    <div className={routerAnimation}>
                                      <Suspense fallback={<Loading />}>
                                        <Component />
                                      </Suspense>
                                    </div>
                                  ) : (
                                    <div className={routerAnimation}>
                                      <Suspense fallback={null}>請重新整理頁面或重新登入</Suspense>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className={routerAnimation}>
                                  <Suspense fallback={null}>請重新整理頁面或重新登入</Suspense>
                                  {redirect && <Redirect to="/auth/login" />}
                                </div>
                              )
                            }}
                          />
                        ))}
                        <Redirect to="/client-404" />
                      </Switch>
                    </CSSTransition>
                  </SwitchTransition>
                </Layout>
              )
            }}
          />
        </ConnectedRouter>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default Router
