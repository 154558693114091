import toast from 'react-hot-toast'
import { teddyApi } from './base.ts'

export const loadFirmInfo = async (firmId) => {
  const { data } = await teddyApi.firmInfo.settingLoadFirmInfo(firmId)
  return data
}

export const loadFirmLiveHoursLog = async (firmId) => {
  const { data } = await teddyApi.firmInfo.settingLoadFirmLiveHoursLog(firmId)
  return data
}

export const loadFirmTopUpInfo = async (firmId) => {
  const { data } = await teddyApi.firmInfo.settingLoadFirmTopUpInfo(firmId)
  return data
}

export const updateFirmInfo = async (firmId, req) => {
  const { data } = await teddyApi.firmInfo.settingUpdateFirmInfo(firmId, req)
  return data
}

export const updateFirmPassword = async (firmId, req) => {
  const { data } = await teddyApi.firmInfo.settingUpdateFirmPassword(firmId, req)
  toast.remove('loadingToast')
  return data
}

export const enableWebhook = async (firmId) => {
  const { data } = await teddyApi.letter.signupEnableWebhook({ firmId: firmId })
  return data
}

export const disableWebhook = async (firmId) => {
  const { data } = await teddyApi.letter.signupDisableWebhook({ firmId: firmId })
  return data
}

export const firmSignOut = async (firmId, userId, deviceId) => {
  const { data } = await teddyApi.firmSignOut.cutrackFirmSignOut({
    firmId: firmId,
    userId: userId,
    deviceId: deviceId,
  })
  return data
}

export const enableSurenotify = async (firmId) => {
  const { data } = await teddyApi.firmInfo.settingEnableSurenotify(firmId)
  return data
}

export const disableSurenotify = async (firmId) => {
  const { data } = await teddyApi.firmInfo.settingDisableSurenotify(firmId)
  return data
}

export const loadFirmSmsPointLog = async (firmId) => {
  const { data } = await teddyApi.firmInfo.settingLoadFirmPointLog(firmId)
  return data
}

export const newFirmSettingInfo = async (req) => {
  const { data } = await teddyApi.newFirmSettingInfo.cutrackNewFirmSettingInfo(req)
  return data
}
